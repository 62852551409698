<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <div slot="title">
      <span style="font-weight: 700;font-size: 18px">{{ form.id ? '编辑' : '新增' }}</span>
    </div>
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"

      label-width="120px"
    >
      <el-form-item
        label="项目名称"
        prop="projectName"
        :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.projectName"
          maxlength="100"
        />
      </el-form-item>
      <el-form-item
        label="项目类型"
        prop="projectType"
        :rules="[ { required: true, message: '项目类型不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.projectType"
          placeholder="请选择业务系统"
        >
          <el-option
            v-for="(item,index) in projectTypeOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="涉及业务"
        prop="productId"
        :rules="[ { required: true, message: '涉及业务不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.productId"
          placeholder="请选择业务系统"
        >
          <el-option
            v-for="(item,index) in productOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="项目发起人"
        prop="sponsorId"
        :rules="[ { required: true, message: '项目发起人不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.sponsorId"
          class="mr10"
          placeholder="请选择人员"
        >
          <el-option
            v-for="(item,index) in users"
            :key="index"
            :label="item.actualName || item.nickName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="项目负责人"
        prop="responsiblePersonId"
        :rules="[ { required: true, message: '项目负责人不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.responsiblePersonId"
          class="mr10"
          placeholder="请选择人员"
        >
          <el-option
            v-for="(item,index) in users"
            :key="index"
            :label="item.actualName || item.nickName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="预计时间"
        prop="time"
        :rules="[ { required: true, message: '完成时间不能为空', trigger: 'change' }]"
      >
        <el-date-picker
          v-model="form.time"
          format="yyyy-MM-dd"
          placement="bottom-start"
          value-format="yyyy-MM-dd"
          type="daterange"
          placeholder="选择日期"
        />
      </el-form-item>
      <el-form-item
        label="项目说明"
      >
        <el-input
          v-model="form.remark"
          type="textarea"
          :autosize="autosize"
          maxlength="100"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { getAllProduct } from '@/api/enterprise/product'
import { queryUserOptions } from '@/api/system/system'
import { saveOrUpdateProjectManage, PorjectManageDetail } from '@/api/project/project'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        planBeginTime: '',
        planEndTime: '',
        time: '',
        projectName: '',
        projectType: '',
        remark: '',
        productId: '',
        responsiblePersonId: '',
        sponsorId: '',
        id: 0,
      },
      productOptions: [],
      projectTypeOptions: [
        { label: '303_645评估', value: 1 },
        { label: '其他', value: 2 },
      ],
      users: [],
    }
  },
  methods: {
    getAllProduct() {
      getAllProduct().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productOptions = resData.data
        }
      })
    },
    queryUserList() {
      queryUserOptions(0)
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.users = resData.data
          }
        })
    },
    getDetail() {
      PorjectManageDetail(this.form.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          // this.form = resData.data
          for (const i in this.form) {
            this.form[i] = resData.data[i]
          }
          this.form.time = []
          if (this.form.planBeginTime && this.form.planEndTime) {
            this.form.time[0] = this.form.planBeginTime
            this.form.time[1] = this.form.planEndTime
          }
        }
      })
    },
    dialogOpen() {
      this.queryUserList()
      this.getAllProduct()
      if (this.form.id) {
        this.getDetail()
      }
    },
    dialogClose() {
      this.form = {
        planBeginTime: '',
        planEndTime: '',
        time: '',
        projectName: '',
        projectType: '',
        remark: '',
        productId: '',
        responsiblePersonId: '',
        sponsorId: '',
        id: 0,
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.planBeginTime = this.form.time[0]
          this.form.planEndTime = this.form.time[1]
          saveOrUpdateProjectManage(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>
