import {
  get, post, Delete, downloadByGet,
} from '@/libs/axios'

export const getProductByPage = params => post('/enterprise/product/GetProductByPage', params)
export const getProductById = params => post('/enterprise/product/GetProductById', params)
export const productScenePage = (productId, params) => post(`/product/scene/${productId}/page`, params)
export const deleteProduct = params => post('/enterprise/product/DeleteById', params)
export const ImportProductInfo = params => post('/enterprise/product/import', params)
export const ImportProductScence = params => post('/product/scene/import', params)
export const ExportProduct = () => downloadByGet(`/enterprise/product/export?_t=${Date.parse(new Date())}`)
export const ExportProductScence = productId => downloadByGet(`/product/scene/${productId}/export?_t=${Date.parse(new Date())}`)
export const ExportProductTemplate = () => downloadByGet(`/enterprise/product/import_template?_t=${Date.parse(new Date())}`)
export const ExportProductScenceTemplate = () => downloadByGet(`/product/scene/import_template?_t=${Date.parse(new Date())}`)
export const getAllProduct = () => get('/enterprise/product/GetAllProduct')
export const GetManagerUsers = () => get('/enterprise/product/managerUsers')
export const GetTechnicalUsers = () => get('/enterprise/product/technicalUsers')
export const GetAssetsProduct = () => get('/enterprise/assets/GetAssetsProduct')
export const ExternalShareSelect = () => get('/external/share/data/product/select')
export const ExternalShareAssestSelect = () => get('/external/share/data/assets/select')
export const ExternalShareThridpatySelect = () => get('/external/share/data/third_party/select')
export const ExternalShareFieldSelect = () => get('/external/share/data/field/select')
export const productSceneSelect = (productId, queryType) => {
  let url = `/product/scene/select?productId=${productId}`
  if (queryType !== undefined) {
    url += `&queryType=${queryType}`
  }
  return get(url)
}
export const getSceneById = (productId, id) => get(`/product/scene/${productId}/info?id=${id}`)
export const deleteSceneById = (productId, id) => Delete(`/product/scene/${productId}/delete?id=${id}`)
export const GetProductPiaSatisfaction = () => get('/enterprise/product/GetProductPiaSatisfaction')

export const saveOrUpdateProduct = params => {
  const submitUrl = params.id ? '/enterprise/product/Update' : '/enterprise/product/Create'
  return post(submitUrl, params)
}
export const saveOrUpdateScene = (productId, params) => {
  const submitUrl = params.id ? `/product/scene/${productId}/update` : `/product/scene/${productId}/create`
  return post(submitUrl, params)
}
// 查询业务下拉
export const queryProductSelect = () => get('/enterprise/product/select')
