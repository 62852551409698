<template>
  <div>
    <b-card>
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
            v-model="listQuery.SearchStr"
            width="180"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
        <el-button
          v-if="savePerm"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="saveDialogShow(1)"
        >
          新增
        </el-button>
      </div>
      <FilterSelectList
        :list-query.sync="listQuery"
        :select-list.sync="selectList"
        @handleFilter="handleFilter"
      />
      <el-table
        v-loading="loading"
        border
        fit
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="projectName"
          :show-overflow-tooltip="true"
          label="项目名称"
        />
        <el-table-column
          prop="remark"
          :show-overflow-tooltip="true"
          label="内容"
        />
        <el-table-column
          prop="productName"
          :show-overflow-tooltip="true"
          label="涉及业务"
        />
        <el-table-column
          prop="sponsorName"
          label="发起人"
        />
        <el-table-column
          prop="responsiblePersonName"
          label="负责人"
        />
        <el-table-column
          prop="participantNames"
          label="参与人员"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          width="100"
          prop="planBeginTime"
          label="开始时间"
        >
          <template slot-scope="scope">
            {{ formatDateStr(scope.row.planBeginTime) }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          prop="planEndTime"
          label="结束时间"
        >
          <template slot-scope="scope">
            {{ formatDateStr(scope.row.planEndTime) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          width="80"
          label="状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="{row}">
            <StatusBadge
                :label="statusObj[row.status]"
                :color="statusColorObj[row.status]"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="120px"
        >
          <template slot-scope="{row}">
            <el-link
              v-if="updatePerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="saveDialogShow(2,row)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="detail"
              :underline="false"
              class="mr10"
              type="primary"
              @click="$router.push({path: 'plan-detail', query: {id: row.id}})"
            >
              <el-tooltip
                content="项目计划"
                placement="top"
                effect="light"
              >
                <i class="el-icon-tickets" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="delPerm"
              :underline="false"
              type="primary"
              @click="del(row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.pageSize"
        @pagination="getList"
      />
    </b-card>
    <PorjectSave
      ref="saveDialog"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
import pagination from '@/@core/components/pagination/Pagination.vue'
import PorjectSave from '@/views/dataIntelligent/project/PorjectSave.vue'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@/@core/utils/utils'
import FilterSelectList from '@/@core/components/filter-select-list/FilterSelectList.vue'
import TableHeaderFilterDropdown from '@/@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import { PorjectManagePage, PorjectManageDel } from '@/api/project/project'

export default {
  components: {
    pagination,
    PorjectSave,
    FilterSelectList,
    TableHeaderFilterDropdown,
  },
  data() {
    const listQuery = {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      OrderField: '',
      order: '',
    }
    return {
      statusObj: {
        0: '待执行',
        1: '待执行',
        2: '执行中',
        3: '已完成',
      },
      statusColorObj: {
        0: '#82868b',
        1: '#82868b',
        2: '#0d40b2',
        3: '#3CB371',
      },
      savePerm: findBtnPerm('project-1'),
      updatePerm: findBtnPerm('project-2'),
      delPerm: findBtnPerm('project-3'),
      detail: findBtnPerm('project-4'),
      loading: false,
      total: 0,
      cookieTypeList: [],
      selectList: [],
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'project-1')
      this.updatePerm = verifyBtnPerm(btnPerms, 'project-2')
      this.delPerm = verifyBtnPerm(btnPerms, 'project-3')
      this.detail = verifyBtnPerm(btnPerms, 'project-4')
    })
    const cacheQuery = this.$store.state.pageQuery.listQuery2
    this.listQuery = { ...cacheQuery }
    this.getList()
  },
  methods: {
    del(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          const ids = [id]
          PorjectManageDel({ ids })
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.handleFilter()
              } else {
                error(resData.msg)
              }
            })
        })
    },
    saveDialogShow(type, row) {
      this.$refs.saveDialog.dialogVisible = true
      if (type === 2) {
        this.$refs.saveDialog.form.id = Number(row.id)
      }
    },
    getList() {
      this.loading = true
      PorjectManagePage(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>

<style scoped>

</style>
