import { downloadByGet, get, post } from '@/libs/axios'

// 查询cookie列表
export const PorjectManagePage = params => post('/project/manage/Page', params)
export const PorjectManageDel = params => post('/project/manage/DeleteByIds', params)
export const StageDel = params => post('/project/stage/Delete', params)
export const PlanDel = params => post('/project/plan/Delete', params)
export const UpdateStatus = params => post('/project/stage/UpdateStatus', params)
export const UpdatePlanStatus = params => post('/project/plan/UpdateStatus', params)
export const PlanAudit = params => post('/project/plan/Audit', params)
export const PorjectManageDetail = id => get(`/project/manage/Details?id=${id}`)
export const GetStageOptions = (manageId,id) => get(`/project/stage/GetOptions?manageId=${manageId}&id=${id}`)
export const GetAssociationOptions = planType => get(`/project/plan/association/options?planType=${planType}`)
export const saveOrUpdateProjectManage = params => {
  const submitUrl = params.id ? '/project/manage/Update' : '/project/manage/Create'
  return post(submitUrl, params)
}
export const saveOrUpdateProjectStage = params => {
  const submitUrl = params.id ? '/project/stage/Update' : '/project/stage/Create'
  return post(submitUrl, params)
}
export const saveOrUpdatePlan = params => {
  const submitUrl = params.id ? '/project/plan/Update' : '/project/plan/Create'
  return post(submitUrl, params)
}
